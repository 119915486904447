export const PREVIEW_PAGE_COMPONENT = 'FrontendPublisherPreviewPage';
export const NOT_FOUND_COMPONENT = 'errors/RoutingNotFound';
export const NOT_FOUND_COMPONENT_PLAIN = 'errors/RoutingNotFoundPlain';

/**
 * Helper for getting Preview CMS content according to the specified deeplink
 */
export function resolveCmsPath(deepLink: string) {
  const { apiInstance } = useShopwareContext();
  return fetch(`${apiInstance.config.endpoint}/store-api/cms/${deepLink}`, {
    headers: {
      'Content-Type': 'application/json',
      'sw-access-key': apiInstance.config.accessToken as string,
      'sw-language-id': apiInstance.config.languageId as string,
    },
  }).then((response) => response.json());
}

/**
 * Helper for getting Draft CMS content according to the specified deeplink
 */
export function resolveDraftsPath(deepLink: string) {
  const { apiInstance } = useShopwareContext();
  return fetch(
    `${apiInstance.config.endpoint}/store-api/draft/preview/api/${deepLink}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'sw-access-key': apiInstance.config.accessToken as string,
        'sw-language-id': apiInstance.config.languageId as string,
      },
    }
  ).then((response) => response.json());
}
